import React from "react";
import { useLocation,useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";

const CreditSuccess = () => {
  const navigator=useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paymentInfo = searchParams.get("paymentInfo");
  var paymentData=[];
  if(paymentInfo!==''){
    paymentData=JSON.parse(paymentInfo);
    // console.log(paymentData);
  }
  setTimeout(() => {
    navigator('/mycredits');
  }, 5000);
  return (
    <>
      <Navbar />
      <div className="row justify-content-center mt-5 pt-5">
        <div className="col-lg-5 mt-5">
          <div className="card">
            <div className="card-body text-center">
              <i class="fa fa-check-circle" style={{fontSize:55,color:"#28a745"}} aria-hidden="true"></i>
              <h4 className="text-success fw-bolder fs-4 mt-3">Payment Successful!</h4>
              
              <p>Payment Id: {paymentData?.id}</p>
              
              <p>Amount :  £ {paymentData?.amount/100}</p>
              
              <small className="text-center text-muted">You will shortly be redirected back to the credits dashboard.</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditSuccess;
