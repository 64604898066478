import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
// import SmallSearch from '../components/SmallSearch'
import { Link } from 'react-router-dom'

const Logout = () => {
    useEffect(() => {
        localStorage.removeItem('userData');
    })
    return (
        <>
            <Navbar />
            {/* <SmallSearch /> */}
            <div className="mar_top bg-primary d-flex align-items-center" style={{height:"100vh"}}>
                <div className="col-lg-5 col-md-7 col-sm-12 mx-auto   pb-4 px-3 logout">
                    <div className="card border-0 ">
                        <div className="card-body d-flex flex-column align-items-center justify-content-center py-5">
                            <h4 className='text-center mb-4 blue'>You have successfully <br /> <span className="fw-bold">logged out </span>of your <br />account</h4>
                            <Link to='/' className="btn btn-primary mb-3">Return To Home</Link>
                            <Link to='/login' className="btn btn-outline-primary mb-3">Login with other account</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Logout