import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
// import SmallSearch from '../components/SmallSearch'
import Footer from "../Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";

const PendingUnlocks = () => {
  const [pendingList, setPendingList] = useState([]);

  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromlocalStorage("userData");

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://argosmob.uk/carloca/public/api/get/bidder/bids",
        {
          bidder_id: storedUserPreferences?.id,
        }
      );
      // console.log(response?.data, "rejeetete");
      const filteredData = response?.data.biddings.filter(
        (item) => item.status === "pending"
      );

      setPendingList(filteredData);
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  function formatEuroAmount(amount) {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + "M"; // Crores
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + "L"; // Lakhs
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K"; // Thousands
    } else {
      return num.toString();
    }
  };
  function convertCcToLiters(cc) {
    if(cc>0){

      return (cc / 1000).toFixed(1);
    }else{
      return 0;
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <div className="new_page">
        {/* <div className="main_banner "></div>
                <SmallSearch /> */}
        <div
          className="pending_unlocks all_listings sml-card container mt-5 py-5"
          style={{ minHeight: "70vh" }}
        >
          <h3 className="py-3 fw-bold fs-5">Pending Unlocks</h3>
          <div className="row">
            {pendingList?.map((car) => (
              <div
                key={car?.id}
                className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 d-flex justify-content-center  col-sm-6 mb-4"
              >
                <div className="card  card_filter_card shadow-lg position-relative">
                  <div
                    className="image-container "
                    style={{ position: "relative" }}
                  >
                    <div className="row h-100">
                      <div className="col-9 h-100 pe-0">
                        <img
                          src={`https://argosmob.uk/carloca/public/uploads/cars/${car?.car?.image}`}
                          className="w-100"
                          loading="lazy"
                          alt="car"
                          style={{
                            height: "100%",
                            width: "100%",
                            borderTopRightRadius: 0,
                          }}
                        />
                      </div>
                      <div className="col-3 h-100  pl-0">
                        <div className="row pl-0 h-100 grid_img">
                          {[...Array(3)].map((_, index) => {
                            const image = car?.car?.gallery[index];
                            if (image) {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    height: "33.3%",
                                    width: "100%",
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    objectFit: "fill",
                                  }}
                                  className="col-12 "
                                >
                                  <img
                                    src={`https://argosmob.uk/carloca/public/uploads/cars-gallery/${image.image}`}
                                    alt=""
                                    loading="lazy"
                                    className="h-100 w-100"
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    height: "33.3%",
                                    width: "100%",
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    objectFit: "fill",
                                  }}
                                  className="col-12 "
                                >
                                  <img
                                    src="img/noimg.jpeg"
                                    alt="Placeholder"
                                    loading="lazy"
                                    className="h-100 w-100"
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pb-1">
                    <div className="d-flex justify-content-between">
                      <Link
                        to={`/cardetails?car=${car?.car?.slug}`}
                        className="text-dark fw-semibold"
                        style={{ fontWeight: "14px" }}
                      >
                        ({car?.car?.year}) {car?.car?.make} {car?.car?.model}
                      </Link>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="text-dark fw-bold">Price Range:</h6>
                      <h6 className="fw-bold price">
                        £{formatEuroAmount(car?.car?.min_price)} - £
                        {formatEuroAmount(car?.car?.max_price)}
                      </h6>
                    </div>
                    <hr className="hr" />
                    <div className="d-flex flex-wrap car_info justify-content-between">
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/automatic.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {car?.car?.transmission_type}
                      </h6>
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/speedometer.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {formatNumber(car?.car?.current_mileage)}
                      </h6>
                      <h6 className="text-dark mb-0  fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/fuel.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {car?.car?.fuel_type}
                      </h6>
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/engine.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {convertCcToLiters(car?.car?.engine_capacity)}L
                      </h6>
                    </div>

                    <hr className="hr" />

                    <div className="d-flex justify-content-between">
                      <h6 className="sml text-dark">Request From:</h6>
                      <h6 className="price ">
                        {car.bidder.first_name} {car.bidder.last_name}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="text-dark">Offer from the user:</h6>
                      <h6 className="fw-bold price">£ {formatEuroAmount(car.amount)}</h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="text-dark">Request Type:</h6>
                      <h6 className="fw-bold text-capitaliz ">{car.bid_request}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content rounded-0">
              <div className="modal-body ">
                <div id="carouselExample" className="carousel slide">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="img/slide1.png"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="img/slide2.png"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="img/slide3.png"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="prev"
                  >
                    <i className="fa-solid fa-chevron-left"></i>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="next"
                  >
                    <i className="fa-solid fa-chevron-right"></i>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="modal-footer border-0 py-1">
                <button
                  type="button"
                  className="btn btn-secondary rounded-0"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PendingUnlocks;
