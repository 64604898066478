import React from "react";
import Home from "./Home";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./index.css";
import { HashRouter as Router, Route, Routes, Outlet } from "react-router-dom";
import Favourites from "./Favourites";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import SignIn from "./pages/SignIn";
import Register from "./pages/Register";
import ConfirmReg from "./pages/ConfirmReg";
import ChangePass from "./pages/ChangePass";
import Cardetail from "./components/Cardetail";
import MyListing from "./dashPages/MyListing";
import ListDetails from "./dashPages/ListDetails";
import ListedPreview from "./dashPages/ListedPreview";
import UnlockReq from "./dashPages/UnlockReq";
import PendingUnlocks from "./dashPages/PendingUnlocks";
import UnlockedCars from "./dashPages/UnlockedCars";
import MyCredits from "./dashPages/MyCredits";
import ProfileSetting from "./dashPages/ProfileSetting";
import DeleteAccount from "./dashPages/DeleteAccount";
import ListMyCar from "./components/ListMyCar";
import BuyPlan from "./dashPages/BuyPlan";
import PaymentDetails from "./dashPages/PaymentDetails";
import Logout from "./pages/Logout";
import PurchaseCredit from "./dashPages/PurchaseCredit";
import AppRequests from "./dashPages/AppUnlockReq";
import Cardetail1 from "./components/Cardetail1";
import FilterCarData from "./components/FilterCarData";
import RejectedRequest from "./dashPages/RejectedRequest";
import ScrollToTop from "./ScrollToTop";
import ThankYou from "./pages/ThankYou";
import CreditSuccess from "./pages/CreditSuccess";
import DealerRegistration from "./pages/DealerRegistration";
import EditCar from "./dashPages/EditCar";
import TermsConditions from "./pages/TermsConditions";
import RejectedRequestBuyer from "./dashPages/RejectedRequestBuyer";
import AcceptOffer from "./pages/AcceptOffer";
import RejectOffer from "./pages/RejectOffer";

const App = () => {
  return (
    <>
      {/* <Home/> */}
      <Router basename="/">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ScrollToTop />
                <Layout>
                  <Outlet />
                </Layout>
              </>
            }
          >
            <Route exact path="/" element={<Home />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/FilterCarData" element={<FilterCarData />} />
            <Route exact path="/listmycar" element={<ListMyCar />} />
            <Route exact path="/fav" element={<Favourites />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgetpassword" element={<ForgetPassword />} />
            <Route exact path="/signin" element={<SignIn />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/dealer-register" element={<DealerRegistration />} />
            <Route exact path="/confreg" element={<ConfirmReg />} />
            <Route exact path="/changepass" element={<ChangePass />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/cardetails" element={<Cardetail />} />
            <Route exact path="/cardetailsNew" element={<Cardetail1 />} />
            <Route exact path="/mylisting" element={<MyListing />} />
            <Route exact path="/listdetails" element={<ListDetails />} />
            <Route exact path="/listpreview" element={<ListedPreview />} />
            <Route exact path="/edit/mycar" element={<EditCar />} />
            <Route path="/offers" element={<UnlockReq />} />
            <Route exact path="/apprequests" element={<AppRequests />} />
            <Route exact path="/pendingunlocks" element={<PendingUnlocks />} />
            <Route exact path="/unlockedcars" element={<UnlockedCars />} />
            <Route exact path="/mycredits" element={<MyCredits />} />
            <Route exact path="/profilesetting" element={<ProfileSetting />} />
            <Route exact path="/deleteAccount" element={<DeleteAccount />} />
            <Route exact path="/buyplan" element={<BuyPlan />} />
            <Route exact path="/paymentdetails" element={<PaymentDetails />} />
            <Route exact path="/purchasecredit" element={<PurchaseCredit />} />
            <Route exact path="/credits-success" element={<CreditSuccess />} />
            <Route exact path="/term-and-condition" element={<TermsConditions />} />
            <Route exact path="/accept-offer" element={<AcceptOffer />} />
            <Route exact path="/decline-offer" element={<RejectOffer />} />
            <Route
              exact
              path="/rejectedrequests"
              element={<RejectedRequest />}
            />
            <Route
              exact
              path="/declined-request"
              element={<RejectedRequestBuyer />}
            />
            <Route exact path="/thankyou" element={<ThankYou />} />
            {/* <Route path="/trip" element={<TripSummary user={user} setUser={setUser}/>} /> */}
          </Route>
        </Routes>
      </Router>
    </>
  );
};
function Layout({ children }) {
  return <div>{children}</div>;
}

export default App;
