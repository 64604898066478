import React, { useEffect, useState,useRef } from "react";
import Navbar from "../components/Navbar";
import Footer from "../Footer";
import "bootstrap/dist/css/bootstrap.min.css";
// import SmallSearch from '../components/SmallSearch'
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";

const UnlockReq = () => {
  const [offerList, setofferList] = useState([]);
  const [deniedList, setdeniedList] = useState([]);
  const [selectId, setSelectId] = useState();
  const [userId, setUserId] = useState();
  const [isProcess, setIsProcess] = useState(false);
  const buttonRef = useRef(null);
  const getDataFromlocalStorage = (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  };
  const storedUserPreferences = getDataFromlocalStorage("userData");

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://argosmob.uk/carloca/public/api/get/offer/bids",
        {
          owner_id: storedUserPreferences?.id,
        }
      );
      // console.log(response?.data, "ddssd");
      setofferList(response?.data?.biddings?.pending);
      setdeniedList(response?.data?.biddings?.rejected);

      //   saveDataTolocalStorage('userData', response?.data?.data);
      // alert(response?.data?.message)
      // setBidValue('')
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  const acceptRejectBid = async (id, status) => {
    // console.log(id, status, userId, "stattst")
    setIsProcess(true);
    try {
      const response = await axios.post(
        "https://argosmob.uk/carloca/public/api/status/bidding",
        {
          user_id: userId,
          bid_id: id,
          status: status,
        }
      );
      setIsProcess(false);
      if (buttonRef.current) {
        buttonRef.current.click();
      }
      fetchData();
    } catch (error) {
      console.log("An error occurred while fetching data.", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  function formatEuroAmount(amount) {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(1) + "M";
    } else if (num >= 100000) {
      return (num / 100000).toFixed(1) + "L";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return num.toString();
    }
  };
  function convertCcToLiters(cc) {
    if (cc > 0) {
      return (cc / 1000).toFixed(1);
    } else {
      return 0;
    }
  }
  return (
    <>
      <Navbar />
      <div className="new_page " >
        {/* <div className="main_banner "></div>
        <SmallSearch/> */}
        <div
          className="unlock_req sml-card all_listings container mt-5 py-2 "
          style={{ minHeight: "100vh" }}
        >
          <h3 className="py-3 mt-4 pb-0 fw-bold fs-5">Offers</h3>
          <p className="text-muted ">Offers will be automatically denied if you didn’t accept the offers within 7 days of request</p>
          <div className="row">
            {offerList?.map((car) => (
              <div
                key={car.id}
                className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 d-flex justify-content-center  col-sm-6 mb-4"
              >
                <div className="card  card_filter_card shadow-lg position-relative">
                  <div
                    className="image-container "
                    style={{ position: "relative" }}
                  >
                    <div className="row h-100">
                      <div className="col-9 h-100 pe-0">
                        <img
                          src={`https://argosmob.uk/carloca/public/uploads/cars/${car?.car?.image}`}
                          className="w-100"
                          loading="lazy"
                          alt="car"
                          style={{
                            height: "100%",
                            width: "100%",
                            borderTopRightRadius: 0,
                          }}
                        />
                      </div>
                      <div className="col-3 h-100  pl-0">
                        <div className="row pl-0 h-100  grid_img">
                          {[...Array(3)].map((_, index) => {
                            const image = car?.car?.gallery[index];
                            if (image) {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    height: "33.3%",
                                    width: "100%",
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    objectFit: "fill",
                                  }}
                                  className="col-12 "
                                >
                                  <img
                                    src={`https://argosmob.uk/carloca/public/uploads/cars-gallery/${image.image}`}
                                    alt=""
                                    loading="lazy"
                                    className="h-100 w-100"
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    height: "33.3%",
                                    width: "100%",
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    objectFit: "fill",
                                  }}
                                  className="col-12 "
                                >
                                  <img
                                    src="img/noimg.jpeg"
                                    alt="Placeholder"
                                    loading="lazy"
                                    className="h-100 w-100"
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                   <i title="This is to inform the seller that it will be automatically rejected after 7 days." className="fa-solid fa-info-circle position-absolute text-primary bg-white rounded-circle" style={{left:10,top:10,cursor:"pointer"}}></i>
                  <div className="card-body pb-1">
                    <div className="d-flex justify-content-between">
                      <Link
                        to={`/cardetails?car=${car?.car?.slug}`}
                        className="text-dark fw-semibold"
                        style={{ fontWeight: "14px" }}
                      >
                        ({car?.car?.year}) {car?.car?.make} {car?.car?.model}
                      </Link>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="text-dark fw-bold">Price Range:</h6>
                      <h6 className="fw-bold price">
                        £{formatEuroAmount(car?.car?.min_price)} - £
                        {formatEuroAmount(car?.car?.max_price)}
                        
                      </h6>
                    </div>
                    <hr className="hr" />
                    <div className="d-flex car_info justify-content-between">
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/automatic.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {car?.car?.transmission_type}
                      </h6>
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/speedometer.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {formatNumber(car?.car?.current_mileage)}
                      </h6>
                      <h6 className="text-dark mb-0  fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/fuel.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {car?.car?.fuel_type}
                      </h6>
                      <h6 className="text-dark mb-0 fw-medium d-flex align-items-center text-capitalize">
                        <img
                          src="./img/engine.png"
                          style={{ height: "25px", width: "25px" }}
                          alt=""
                        />
                        {convertCcToLiters(car?.car?.engine_capacity)}L
                      </h6>
                    </div>

                    <hr className="hr" />

                    <div className="d-flex justify-content-between">
                      <h6 className="sml text-dark">Request From:</h6>
                      <h6 className="price ">
                        {car.bidder.first_name} {car.bidder.last_name}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="text-dark">Offer from the user:</h6>
                      <h6 className="fw-bold price">
                        £ {formatEuroAmount(car.amount)}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="text-dark">Request Type:</h6>
                      <h6 className="fw-bold text-capitalize">
                         {car.bid_request}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center column mt-2 flex-wrap">
                      <button
                        style={{ fontSize: "12px" }}
                        className="btn btn-danger mb-2 btn-sm"
                        data-bs-toggle="modal"
                        onClick={() => {
                          setSelectId(car.id);
                          setUserId(car.bidder?.id);
                        }}
                        data-bs-target="#exampleModal2"
                      >
                        Deny Communication
                      </button>
                      <button
                        style={{ fontSize: "12px" }}
                        className="btn btn-primary mb-2 btn-sm new_btn"
                        data-bs-toggle="modal"
                        onClick={() => {
                          setSelectId(car.id);
                          setUserId(car.bidder?.id);
                        }}
                        data-bs-target="#exampleModal1"
                      >
                        Accept Communication
                      </button>
                    </div>
                    <div className="d-flex mb-2 justify-content-between  align-items-center  ">
                      <div className="d-flex align-items-center">
                        {car?.car?.owner?.city && (
                          <>
                            <img
                              src="./img/pin.png"
                              style={{ height: "20px", width: "20px" }}
                              alt=""
                            />
                            <h6 className="text-dark mb-0 pb-0 fw-medium d-flex align-items-center text-capitalize">
                              {car?.car?.owner?.city} (
                              {car?.car?.owner?.country})
                            </h6>
                          </>
                        )}
                      </div>
                      <div>
                        <p className="text-primary fs-7 pb-0 mb-0">
                          More <i className="fa fa-arrow-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
          <div className="modal-dialog modal-lg">
            <div className="modal-content rounded-0">
              <div className="modal-body ">
                <div id="carouselExample" className="carousel slide">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="img/slide1.png"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="img/slide2.png"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="img/slide3.png"
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="prev"
                  >
                    <i className="fa-solid fa-chevron-left"></i>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="next"
                  >
                    <i className="fa-solid fa-chevron-right"></i>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="modal-footer border-0 py-1">
                <button
                  type="button"
                  className="btn btn-secondary rounded-0"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Accept offer popup */}
        <div className="modal fade " id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body text-center mt-3 small_modal">
                <h5 className="">
                  Are you sure you want to accept this request?
                </h5>

                {/* <h4 className='blue'>£65000</h4> */}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    
                    onClick={() => acceptRejectBid(selectId, "accept")}
                    className="btn btn-primary btn-sm mx-2"
                    // data-bs-dismiss="modal"
                    disabled={isProcess}
                  >
                    {isProcess?"Processing...":"Yes"}
                  </button>
                  <button
                    className="btn btn-danger btn-sm mx-2"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
              <div className="modal-footer border-0">
                <button
                  ref={buttonRef}
                  className="btn btn-secondary btn-sm d-none"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Deny offer popup */}
        <div className="modal fade"  id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true" >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body text-center mt-3 small_modal">
                <h5 className="">
                  Are you sure you want to deny this request?
                </h5>
                {/* <h4 className='blue'>£65000</h4> */}
                <div className="d-flex justify-content-center mt-3">
                  <button
                    onClick={() => acceptRejectBid(selectId, "reject")}
                    className="btn btn-primary btn-sm mx-2"
                    data-bs-dismiss="modal"
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-danger btn-sm mx-2"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Popup of person detail who have given the offer*/}
        {/* <div
          className="modal fade"
          id="exampleModal3"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel3"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header pb-0">
                <h4>Contact Details</h4>
              </div>
              <div className="modal-body text-center blue mt-3 small_modal">
                <h5>
                  <b>Name - </b>John Doe
                </h5>
                <h5>
                  <b>Contact - </b>8585888854
                </h5>
                <Link className="blue">
                  <h5>
                    <b>Email - </b>john123@example.com
                  </h5>
                </Link>
              </div>

              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div> */}

        <Footer />
      </div>
    </>
  );
};

export default UnlockReq;
